import _ from 'lodash';
import React from 'react';
import { DndProvider } from 'react-dnd';
import { ApolloProvider } from '@apollo/client';
import { QueryParamProvider } from 'use-query-params';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { BrowserRouter as Router, Switch, Redirect, Route as ReactRouterRoute } from 'react-router-dom';

import Route from './route';
import routes from '../routes';
import { RouteProps } from '../types';
import graphqlClient from '../graphql/client';

const App: React.FC<{}> = () => (
  <ApolloProvider client={graphqlClient}>
    <DndProvider backend={HTML5Backend}>
      <Router>
        <QueryParamProvider ReactRouterRoute={ReactRouterRoute}>
          <Switch>
            {routes.map((route, i) => {
              const redirect = _.get(route, 'redirect');
              if (_.isString(redirect)) {
                return (
                  <Route exact key={`route-${i}`} path={route.path} component={() => <Redirect to={redirect} />} />
                );
              }
              return <Route key={`route-${i}`} {...(route as RouteProps)} />;
            })}
          </Switch>
        </QueryParamProvider>
      </Router>
    </DndProvider>
  </ApolloProvider>
);

export default App;
