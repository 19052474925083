import _ from 'lodash';
import React from 'react';

type Cancelable = {
  cancel: () => void;
};
type onClickFn<Props = {}> = (e: MouseEvent, props: Props) => void;
type onDoubleClick<Props = {}> = onClickFn<Props>;
type Options<Props = {}> = {
  wait?: number;
  onClick?: onClickFn<Props>;
  onDoubleClick?: onDoubleClick<Props>;
};
type Result<Props = {}> = (props?: { props?: Props; [x: string]: any }) => { onClick: (e: any) => void };

export default function useClick<Props = void>(
  options: Options<Props> = {},
  deps: React.DependencyList = [],
): Result<Props> {
  const clickedOnce = React.useRef<boolean | undefined>();
  const delayedClick = React.useRef<(onClickFn<Props> & Cancelable) | undefined>();
  const doClick = React.useCallback<onClickFn<Props>>((e, props) => {
    const { onClick } = options;
    clickedOnce.current = undefined;
    if (_.isFunction(onClick)) {
      onClick(e, props);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, _.concat([], options, deps));
  const doDoubleClick = React.useCallback<onDoubleClick<Props>>((e, props) => {
    const { onDoubleClick } = options;
    clickedOnce.current = false;
    if (_.isFunction(onDoubleClick)) {
      onDoubleClick(e, props);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, _.concat([], options, deps));
  const handleClick = React.useCallback<onClickFn<Props>>((e, props) => {
    const { wait } = options;
    if (!delayedClick.current) {
      delayedClick.current = _.debounce((e: any) => doClick(e, props), wait);
    }
    if (clickedOnce.current) {
      delayedClick.current.cancel();
      doDoubleClick(e, props);
    } else {
      delayedClick.current(e, props);
      clickedOnce.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, _.concat([], doClick, doDoubleClick, options, deps));
  return React.useCallback<Result<Props>>(
    props =>
      _.merge({}, _.omit(props, ['props']), {
        onClick: (e: any) => {
          e.stopPropagation();
          handleClick(e, _.get(props, 'props', {} as any));
        },
      }),
    [handleClick],
  );
}
