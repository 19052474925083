import _ from 'lodash';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import { ApolloClient as InternalApolloClient, InMemoryCache, ApolloLink, Observable, Operation } from '@apollo/client';

import { getToken } from '../util/auth';

export type GraphQLClientCache = Record<string, unknown>;
export type GraphQLClient = InternalApolloClient<GraphQLClientCache>;

export const createGraphQLClient: (uri?: string) => GraphQLClient = uri => {
  const request = async (operation: Operation) => {
    const token = getToken();
    if (_.isString(token)) {
      operation.setContext({ headers: { authorization: `Bearer ${token}` } });
    }

    if (process.env.NODE_ENV === 'development') console.log(operation);
  };
  const requestLink = new ApolloLink(
    (operation, forward) =>
      new Observable(observer => {
        let handle: ZenObservable.Subscription;
        Promise.resolve(operation)
          .then(request)
          .then(() => {
            handle = forward(operation).subscribe({
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            });
          })
          .catch(observer.error.bind(observer));
        return () => {
          if (handle) {
            handle.unsubscribe();
          }
        };
      }),
  );
  return new InternalApolloClient({
    link: ApolloLink.from([
      onError(({ graphQLErrors, networkError }) => {
        if (process.env.NODE_ENV === 'development') {
          if (graphQLErrors)
            graphQLErrors.forEach(({ message, locations, path }) => {
              console.log('[GraphQL error][Message] ', message);

              console.log('[GraphQL error][Location] ', locations);

              console.log('[GraphQL error][Path] ', path);
            });

          if (networkError) console.log('[Network error] ', networkError);
        }
      }),
      requestLink,
      createUploadLink({ uri, credentials: 'same-origin' }),
    ]),
    cache: new InMemoryCache(),
  });
};

export default _.once(createGraphQLClient)(process.env.GRAPHQL_SERVER_URI);
