import _ from 'lodash';
import React, { useState } from 'react';
import * as Yup from 'yup';
import cx from 'classnames';
import moment from 'moment';
import { Formik, Form, FormikValues, FieldArray } from 'formik';
import Switch from 'react-switch';
import { gql, useApolloClient, useMutation } from '@apollo/client';

import ROLLInput from './rollInput';
import StockInput from './stockInput';
import ArticleSelect from './articleSelect';

import useClick from '../../../../hooks/useClick';
import styles from './productionForm.module.scss';

import 'moment/locale/fr';

import type { Article } from './articleSelect';

type WithTypename<T = any> = Omit<T, '__typename'> & { __typename: string };
type ArrayOf<T = any> = T[];

type Locale = 'fr';
const DEFAULT_LOCALE = 'fr';

type DateProps = {
  className?: string;
  date: moment.Moment;
};

const Date: React.FC<DateProps> = ({ date, className }) => <div className={cx(className)}>{date.format('L')}</div>;

function useToday({ at, locale = DEFAULT_LOCALE }: { at?: moment.Moment; locale?: Locale } = {}): moment.Moment {
  return React.useMemo(() => (moment.isMoment(at) ? at.clone() : moment()).locale(locale), [at, locale]);
}

function useComputationDate({
  at,
  locale = DEFAULT_LOCALE,
}: { at?: moment.Moment; locale?: Locale } = {}): moment.Moment {
  const today = useToday({ at, locale });
  return React.useMemo(() => today.startOf('week').subtract(1, 'day'), [today]);
}

type TodayProps = {
  locale?: Locale;
  at?: moment.Moment;
  className?: string;
};

const Today: React.FC<TodayProps> = ({ at, locale, className }) => {
  const today = useToday({ at, locale });
  return <Date className={cx(className)} date={today} />;
};

type WeekProps = {
  offset?: number;
  locale?: Locale;
  at?: moment.Moment;
  className?: string;
};

const Week: React.FC<WeekProps> = ({ at, offset, locale = DEFAULT_LOCALE, className }) => {
  const week = moment(at).startOf('isoWeek').add(offset, 'week').locale(locale);
  return (
    <div className={cx(className)}>
      <Date date={week} />
      <br />
    </div>
  );
};

type ResetButtonProps = {
  disabled?: boolean;
  className?: string;
  isSubmitting?: boolean;
  onClick?: () => void;
};

const ResetButton: React.FC<ResetButtonProps> = ({ disabled, className, isSubmitting, onClick }) => {
  const getResetClickProps = useClick({
    onClick: e => {
      e.preventDefault();
      if (_.isFunction(onClick)) {
        onClick();
      }
    },
  });
  return (
    <div className={cx(className)}>
      <div
        {...getResetClickProps({
          disabled: disabled || isSubmitting,
          className: cx(styles.button, { [styles.busy]: isSubmitting, [styles.disabled]: disabled || isSubmitting }),
        })}
      >
        Réinitialiser
      </div>
    </div>
  );
};

type SubmitButtonProps = {
  disabled?: boolean;
  className?: string;
  isSubmitting?: boolean;
};

const SubmitButton: React.FC<SubmitButtonProps> = ({ disabled, className, isSubmitting }) => (
  <div className={cx(className)}>
    <button
      type="submit"
      disabled={disabled || isSubmitting}
      className={cx(styles.button, styles.primary, {
        [styles.busy]: isSubmitting,
        [styles.disabled]: disabled || isSubmitting,
      })}
    >
      {isSubmitting ? (
        <>
          <span
            role="status"
            aria-hidden="true"
            className={cx({
              'spinner-border': true,
              'spinner-border-sm': true,
            })}
          />{' '}
          Sauvegarde en cours...
        </>
      ) : (
        'Sauvegarder'
      )}
    </button>
  </div>
);

type TableCells = ArrayOf<{
  label: string;
  value?: number;
  readOnly?: boolean;
  className?: string;
  precision?: number;
  update: (v?: number) => void;
}>;

type TableHeaderProps = {
  displayUc: boolean;
  onChange: (v: boolean) => void;
};

type TableRowProps = {
  index: number;
  locale?: Locale;
  isLast?: boolean;
  article?: Article;
  at?: moment.Moment;
  displayUc: boolean;
  cells: TableCells;
};

const TableHeader: React.FC<TableHeaderProps> = ({ displayUc, onChange }) => {
  return (
    <>
      <div className={cx(styles.table__row, styles['table__row--header'], { [styles.first]: true })}>
        <div className={cx(styles.table__cell, styles['table__cell--switcher'], { [styles.first]: true })}>
          <div>kg</div>
          <Switch
            onChange={checked => onChange(checked)}
            checked={displayUc}
            height={20}
            width={40}
            onColor="#888"
            uncheckedIcon={false}
            checkedIcon={false}
          />
          <div>uvc</div>
        </div>
        <strong className={cx(styles.table__cell, styles['table__cell--prevision-vente'], { [styles.first]: true })}>
          PREVISION DES VENTES
        </strong>
        <strong className={cx(styles.table__cell, styles['table__cell--prevision'], { [styles.first]: true })}>
          PREVISION
        </strong>
      </div>
      <div className={cx(styles.table__row)}>
        <strong className={cx(styles.table__cell, styles['table__cell--week'])}>Semaine du lundi</strong>
        <strong className={cx(styles['table__cell--meteo'], { [styles.first]: true })}>METEO</strong>
        <strong className={cx(styles['table__cell--retenue'], { [styles.first]: true })}>RETENUE</strong>
        <strong className={cx(styles['table__cell--production'], { [styles.first]: true })}>PRODUCTION</strong>
      </div>
    </>
  );
};

const TableRow: React.FC<TableRowProps> = ({ at, locale, index, isLast, article, cells, displayUc }) => {
  return (
    <>
      <div key={`table-row-${index + 2}`} className={cx(styles.table__row)}>
        <Week at={at} locale={locale} offset={index} className={cx(styles.table__cell, styles['table__cell--week'])} />
        {_.map(cells, ({ className, value, readOnly, precision, update }) => (
          <ROLLInput
            readOnly={readOnly}
            precision={precision}
            className={cx(className, { [styles.last]: isLast })}
            value={
              _.some([article, value], _.isNil) || (!_.isNil(article) && _.isNil(article.uc_par_roll))
                ? value
                : value! / article!.uc_par_roll!
            }
            update={(roll?: number) =>
              update(
                _.isNil(roll) || (!_.isNil(article) && _.isNil(article.uc_par_roll))
                  ? roll
                  : roll * article!.uc_par_roll!,
              )
            }
            ucParRoll={article ? article.uc_par_roll || 0 : 0}
            coefficientKg={article ? article.coefficient_kg || 0 : 0}
            displayUc={displayUc}
          />
        ))}
      </div>
    </>
  );
};

interface FormValues extends FormikValues {
  article?: Article;
  emptyPrediction: boolean;
  rows?: ArrayOf<{ date?: Date; meteo?: number; retenue?: number; production?: number }>;
  stock?: {
    min?: number;
    max?: number;
  };
}

type ProductionFormProps = {
  locale?: Locale;
  nbWeeks?: number;
  at?: moment.Moment;
  precision?: number;
  className?: string;
};

const ProductionForm: React.FC<ProductionFormProps> = ({
  locale,
  className,
  nbWeeks = 8,
  precision = 3,
  at = moment(),
}) => {
  const [displayUc, setDisplayUc] = useState(true);
  const initialValues: FormValues = { emptyPrediction: false, rows: _.map(_.range(1, nbWeeks + 1), () => ({})) };
  const initialValuesRef = React.useRef(initialValues);
  const computationDate = useComputationDate({ at, locale });
  const [updateChoixProduction] = useMutation(
    gql`
      mutation ($input: ChoixProductionInput!) {
        updateChoixProduction(input: $input) {
          rows: details {
            date
            meteo: prevue
            retenue
            production
          }
        }
      }
    `,
    { awaitRefetchQueries: true },
  );
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async ({ article, rows, ...values }, { setValues }) => {
        const newValues = _.isNil(article)
          ? values
          : _.merge({}, values, {
              article,
              rows: _.map(rows, ({ date, ...row }) =>
                _.merge(
                  {},
                  { date },
                  _.mapValues(row, (uc?: number) =>
                    _.isNil(uc) ? uc : Math.round((uc + Number.EPSILON) * 10 ** precision) / 10 ** precision,
                  ),
                ),
              ),
            });
        const { errors, data } = await updateChoixProduction({
          variables: {
            input: {
              article: _.get(newValues, 'article.article', null),
              date_calcul: computationDate.format(moment.HTML5_FMT.DATE),
              stock_min: _.get(newValues, 'stock.min', null),
              stock_max: _.get(newValues, 'stock.max', null),
              details: _.reduce(
                _.get(newValues, 'rows', []),
                (acc, row) => [...acc, { date: row.date, retenue: row.retenue, production: row.production }],
                [] as any,
              ),
            },
          },
        });
        const updatedValues = _.merge(
          {},
          newValues,
          { emptyPrediction: !_.isNil(errors) },
          data && data.predictions && data.predictions.rows
            ? {
                rows: _.map(data.predictions.rows, _.unary(_.partialRight(_.omit, ['__typename']))),
              }
            : null,
        );
        initialValuesRef.current = updatedValues;
        setValues(updatedValues);
      }}
      validationSchema={Yup.object().shape({
        emptyPrediction: Yup.boolean().required(),
        article: Yup.object()
          .shape({
            article: Yup.string().required(),
            libelle: Yup.string().nullable(),
            uc_par_roll: Yup.number().nullable(),
            coefficient_kg: Yup.number().nullable(),
            coefficient_roll: Yup.number().nullable(),
            stock_min: Yup.number().integer().nullable(),
            stock_max: Yup.number().integer().nullable(),
          })
          .nullable(),
        rows: Yup.array()
          .of(
            Yup.object()
              .shape({
                date: Yup.date().nullable(),
                meteo: Yup.number().nullable(),
                retenue: Yup.number().nullable(),
                production: Yup.number().nullable(),
              })
              .nullable(),
          )
          .nullable(),
        stock: Yup.object()
          .shape({
            min: Yup.number().integer().nullable(),
            max: Yup.number().integer().nullable(),
          })
          .nullable(),
      })}
    >
      {({ values, isSubmitting, setFieldValue, resetForm, setValues }) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const graphqlClient = useApolloClient();
        return (
          <Form className={cx(styles.form, className)}>
            <div className={cx(styles.form__header)}>
              <strong className={cx(styles['form__header--reference-produit'])}>Référence Produit</strong>
              <ArticleSelect
                onClear={() => {
                  const newValues = initialValues;
                  initialValuesRef.current = newValues;
                  resetForm({ values: newValues });
                }}
                onChange={async article => {
                  initialValuesRef.current = initialValues;
                  resetForm({ values: initialValues });
                  const { error, data } = await graphqlClient.query<{
                    predictions: {
                      // eslint-disable-next-line camelcase
                      stock_min?: number;
                      // eslint-disable-next-line camelcase
                      stock_max?: number;
                      rows?: ArrayOf<
                        WithTypename<{ date?: Date; meteo?: number; retenue?: number; production?: number }>
                      >;
                    };
                  }>({
                    fetchPolicy: 'network-only',
                    variables: {
                      article: article.article,
                      date: computationDate.format(moment.HTML5_FMT.DATE),
                    },
                    query: gql`
                      query ($article: String!, $date: Date) {
                        predictions: choixProduction(article: $article, date: $date) {
                          stock_min
                          stock_max
                          rows: details {
                            date
                            meteo: prevue
                            retenue
                            production
                          }
                        }
                      }
                    `,
                  });
                  const newValues = _.merge(
                    {},
                    values,
                    { emptyPrediction: !_.isNil(error) },
                    data && data.predictions
                      ? {
                          article: _.merge({}, _.omit(article, 'stock_min', 'stock_max'), {
                            stock_min: _.get(data.predictions, 'stock_min', article.stock_min),
                            stock_max: _.get(data.predictions, 'stock_max', article.stock_max),
                          }),
                        }
                      : { article },
                    data && data.predictions && data.predictions.rows
                      ? {
                          rows: _.map(data.predictions.rows, _.unary(_.partialRight(_.omit, ['__typename']))),
                        }
                      : null,
                  );
                  initialValuesRef.current = newValues;
                  setValues(newValues);
                }}
              />
              <strong className={cx(styles['form__header--date'])}>Situation au</strong>
              <Today at={at} locale={locale} className={cx(styles['date--today'])} />
            </div>
            <FieldArray name="rows">
              {() => (
                <div className={cx(styles.form__table)}>
                  <TableHeader displayUc={displayUc} onChange={v => setDisplayUc(v)} />
                  {_.isArray(values.rows) &&
                    _.size(values.rows) > 0 &&
                    _.map(values.rows, ({ meteo, retenue, production }, index) => (
                      <TableRow
                        at={at}
                        index={index}
                        locale={locale}
                        article={values.article}
                        isLast={index + 1 >= _.size(values.rows)}
                        displayUc={displayUc}
                        cells={[
                          {
                            precision,
                            value: meteo,
                            label: 'METEO',
                            readOnly: true,
                            className: cx(styles['table__cell--meteo']),
                            update: v => setFieldValue(`rows.${index}.meteo`, v, true),
                          },
                          {
                            precision,
                            value: retenue,
                            label: 'RETENUE',
                            className: cx(styles['table__cell--retenue']),
                            update: v => setFieldValue(`rows.${index}.retenue`, v, true),
                            readOnly: isSubmitting || _.some([values.article, meteo], _.isNil),
                          },
                          {
                            precision,
                            value: production,
                            label: 'PRODUCTION',
                            className: cx(styles['table__cell--production']),
                            update: v => setFieldValue(`rows.${index}.production`, v, true),
                            readOnly: isSubmitting || _.some([values.article, meteo], _.isNil),
                          },
                        ]}
                      />
                    ))}
                </div>
              )}
            </FieldArray>
            <div className={cx(styles.form__footer)}>
              <div className={cx(styles.stocks)}>
                <div className={cx(styles.stock)}>
                  <strong className={cx(styles.stock__label)}>Stock MIN</strong>
                  <StockInput
                    className={cx(styles.stock__input)}
                    update={v => setFieldValue('stock.min', v, true)}
                    readOnly={_.isNil(values.article) || isSubmitting}
                    value={_.get(values, 'stock.min', _.get(values, 'article.stock_min'))}
                  />
                </div>
                <div className={cx(styles.stock)}>
                  <strong className={cx(styles.stock__label)}>Stock MAX</strong>
                  <StockInput
                    className={cx(styles.stock__input)}
                    update={v => setFieldValue('stock.max', v, true)}
                    readOnly={_.isNil(values.article) || isSubmitting}
                    value={_.get(values, 'stock.max', _.get(values, 'article.stock_max'))}
                  />
                </div>
              </div>
              <div className={cx(styles.actions)}>
                <ResetButton
                  isSubmitting={isSubmitting}
                  disabled={_.isEqual(values, initialValuesRef.current)}
                  onClick={() => resetForm({ values: initialValuesRef.current })}
                />
                <SubmitButton isSubmitting={isSubmitting} disabled={_.isEqual(values, initialValuesRef.current)} />
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ProductionForm;
