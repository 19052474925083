import _ from 'lodash';
import { useState, useMemo, Dispatch, SetStateAction, useEffect } from 'react';

export default function useToggle(initial: boolean | (() => boolean) = false): {
  on: boolean;
  set: Dispatch<SetStateAction<boolean>>;
  reset: () => void;
  toggle: () => void;
} {
  const [on, setToggle] = useState(initial);
  useEffect(() => {
    setToggle(_.isFunction(initial) ? initial() : initial);
  }, [initial]);
  return useMemo(
    () => ({ on, set: setToggle, reset: () => setToggle(initial), toggle: () => setToggle(prev => !prev) }),
    [initial, on],
  );
}
