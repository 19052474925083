import _ from 'lodash';
import React from 'react';
import cx from 'classnames';
import { GoChevronUp, GoChevronDown } from 'react-icons/go';

import useClick from '../../../../hooks/useClick';
import useFocus from '../../../../hooks/useFocus';
import useHover from '../../../../hooks/useHover';
import styles from './productionForm.module.scss';

export type InputNumberComponentInfoProps = {
  className?: string;
  value: number | null;
};

export type CommonInputNumberProps<T = any> = Omit<T, 'value' | 'readOnly' | 'className' | 'update'> & {
  value?: number;
  readOnly?: boolean;
  className?: string;
  update?: (number?: number) => void;
};

export type InputNumberComponentsProp = {
  Info?: React.ComponentType<InputNumberComponentInfoProps>;
};

export type InputNumberProps = {
  min?: string;
  max?: string;
  step?: string;
  loop?: boolean;
  value?: string;
  readOnly?: boolean;
  className?: string;
  precision?: number;
  onChange?: (newValue: string) => void;
  components?: InputNumberComponentsProp;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  accepts?: ('integer' | 'negative')[] | ('float' | 'negative')[];
};

const InputNumber: React.FC<InputNumberProps> = ({
  min,
  max,
  step,
  loop,
  value,
  accepts,
  readOnly,
  className,
  precision,
  components,
  onChange,
  onKeyDown,
}) => {
  const isEmptyString = React.useCallback((s: string) => s.trim().length === 0, []);
  const isZero = React.useCallback(
    (s: string, { float, partial, negative }: { float?: boolean; partial?: boolean; negative?: boolean } = {}) => {
      let regexp = /^0$/;
      if (negative) {
        regexp = /^-?0$/;
        if (float) {
          regexp = partial ? /^-?0\.$/ : /^-?0\.0$/;
        }
      } else if (float) {
        regexp = partial ? /^0\.$/ : /^0\.0$/;
      }
      return regexp.test(s.trim());
    },
    [],
  );
  const isInteger = React.useCallback((s: string, { negative }: { negative?: boolean } = {}) => {
    const makeRegexp = (r: string) => {
      let regexp = '^';
      if (negative) {
        regexp += '-?';
      }
      regexp += r;
      regexp += '$';
      return regexp;
    };
    return _.some(_.map(['0', '[1-9][0-9]*'], r => RegExp(makeRegexp(r)).test(s.trim())));
  }, []);
  const isFloat = React.useCallback(
    (
      s: string,
      { negative, partial, precision }: { negative?: boolean; partial?: boolean; precision?: number } = {},
    ) => {
      const makeRegexp = (r: string) => {
        let regexp = '^';
        if (negative) {
          regexp += '-?';
        }
        regexp += r;
        regexp +=
          !_.isNil(precision) && _.isSafeInteger(precision) && precision > 0
            ? `\\.([0-9]{1,${precision}})`
            : '\\.([0-9]+)';
        if (partial) {
          regexp += '?';
        }
        regexp += '$';
        return regexp;
      };
      return _.some(_.map(['0', '[1-9][0-9]*'], r => RegExp(makeRegexp(r)).test(s.trim())));
    },
    [],
  );
  const isNegative = React.useCallback((s: string) => /^-$/.test(s.trim()), []);
  const acceptsFloat = React.useMemo(() => _.isArray(accepts) && (accepts as 'float'[]).includes('float'), [accepts]);
  const acceptsInteger = React.useMemo(
    () => _.isArray(accepts) && (accepts as 'integer'[]).includes('integer'),
    [accepts],
  );
  const acceptsNegative = React.useMemo(() => _.isArray(accepts) && accepts.includes('negative'), [accepts]);
  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (_.isFunction(onChange)) {
        let s = e.target.value;
        if (acceptsFloat) {
          if (s === '.') {
            s = '0.';
          } else if (acceptsNegative && s === '-.') {
            s = '-0.';
          }
        }
        if (
          isEmptyString(s) ||
          (acceptsNegative && isNegative(s)) ||
          (acceptsInteger &&
            (isZero(s, { negative: acceptsNegative }) || isInteger(s, { negative: acceptsNegative }))) ||
          (acceptsFloat &&
            (isZero(s, { negative: acceptsNegative }) ||
              isZero(s, { negative: acceptsNegative, float: true }) ||
              isZero(s, { negative: acceptsNegative, float: true, partial: true }) ||
              isInteger(s, { negative: acceptsNegative }) ||
              isFloat(s, { precision, negative: acceptsNegative }) ||
              isFloat(s, { precision, negative: acceptsNegative, partial: true })))
        ) {
          onChange(s);
        }
      }
    },
    [
      acceptsFloat,
      acceptsInteger,
      acceptsNegative,
      isEmptyString,
      isFloat,
      isInteger,
      isNegative,
      isZero,
      onChange,
      precision,
    ],
  );
  const cast = React.useCallback(
    (s?: string) => {
      let n: number | null = null;
      if (_.isString(s)) {
        if (isZero(s, { float: acceptsFloat, negative: acceptsNegative })) {
          n = 0;
        } else if (isInteger(s, { negative: acceptsNegative })) {
          n = parseInt(s, 10);
          if (_.isNaN(n)) {
            n = null;
          }
        } else if (isFloat(s, { precision, negative: acceptsNegative })) {
          n = parseFloat(s);
          if (_.isNaN(n)) {
            n = null;
          }
        }
      }
      return n;
    },
    [acceptsFloat, acceptsNegative, isFloat, isInteger, isZero, precision],
  );
  const format = React.useCallback(
    (n: number) => {
      if (_.isString(step) && isFloat(step, { precision })) {
        const fractionDigits = step.split('.')[1].length;
        if (fractionDigits > 0) {
          return n.toFixed(fractionDigits);
        }
      }
      return JSON.stringify(n);
    },
    [isFloat, precision, step],
  );
  const updateValue = React.useCallback(
    (op: '+' | '-') => {
      let n = cast(value) || 0;

      const stepValue = cast(step);
      if (_.isFinite(stepValue)) {
        switch (op) {
          case '+':
            n += stepValue!;
            break;
          case '-':
            n -= stepValue!;
            break;
          default:
            break;
        }
      }

      const minValue = cast(min);
      const maxValue = cast(max);
      if (_.isFinite(minValue) && n < minValue!) {
        return format(loop && _.isFinite(maxValue) ? maxValue! : minValue!);
      }
      if (_.isFinite(maxValue) && n > maxValue!) {
        return format(loop && _.isFinite(minValue) ? minValue! : maxValue!);
      }

      return format(n);
    },
    [cast, format, loop, max, min, step, value],
  );
  const incrementValue = React.useCallback(() => updateValue('+'), [updateValue]);
  const incrementValueRef = React.useRef(incrementValue);
  React.useEffect(() => {
    incrementValueRef.current = incrementValue;
  }, [incrementValue]);
  const decrementValue = React.useCallback(() => updateValue('-'), [updateValue]);
  const decrementValueRef = React.useRef(decrementValue);
  React.useEffect(() => {
    decrementValueRef.current = decrementValue;
  }, [decrementValue]);
  const handleUpdate = React.useCallback(
    (update: () => string | undefined) => {
      const newValue = update();
      if (_.isFunction(onChange) && _.isString(newValue)) {
        onChange(newValue);
      }
    },
    [onChange],
  );
  const handleUpdateRef = React.useRef(handleUpdate);
  React.useEffect(() => {
    handleUpdateRef.current = handleUpdate;
  }, [handleUpdate]);
  const handleKeyDown = React.useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      switch (e.key) {
        case 'ArrowUp':
          handleUpdate(incrementValue);
          break;
        case 'ArrowDown':
          handleUpdate(decrementValue);
          break;
        default:
          break;
      }
      if (_.isFunction(onKeyDown)) {
        onKeyDown(e);
      }
    },
    [decrementValue, handleUpdate, incrementValue, onKeyDown],
  );
  const getIncrementClickProps = useClick({
    onClick: () => {
      handleUpdateRef.current(incrementValueRef.current);
    },
  });
  const getDecrementClickProps = useClick({
    onClick: () => {
      handleUpdateRef.current(decrementValueRef.current);
    },
  });
  const { focused: inputFocused, bind: inputFocusProps } = useFocus();
  const { hovered: containerHovered, bind: containerHoverProps } = useHover();
  return (
    <div className={cx(styles.inputNumber, className)}>
      <div
        className={cx(styles.inputNumber__container, {
          [styles['inputNumber__container--isFocused']]: !readOnly && inputFocused,
          [styles['inputNumber__container--isHovered']]: !readOnly && containerHovered,
          [styles['inputNumber__container--hasHandlers']]: !readOnly && _.isString(step),
        })}
        {...containerHoverProps}
      >
        <input
          type="text"
          value={value}
          readOnly={readOnly}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          className={cx(styles.inputNumber__input, { [styles['inputNumber__input--readOnly']]: readOnly })}
          {...inputFocusProps}
        />
        {!readOnly && _.isString(step) && (
          <div className={cx(styles.inputNumber__handlers)}>
            <GoChevronUp
              {...getIncrementClickProps({
                className: cx(styles.inputNumberHandler, styles['inputNumberHandler--up']),
              })}
            />
            <GoChevronDown
              {...getDecrementClickProps({
                className: cx(styles.inputNumberHandler, styles['inputNumberHandler--down']),
              })}
            />
          </div>
        )}
      </div>
      {_.isPlainObject(components) && !_.isNil(components!.Info) && (
        <div className={cx(styles.inputNumber__info)}>
          {React.createElement(components!.Info, { className: cx(styles.inputNumberInfo), value: cast(value) }, null)}
        </div>
      )}
    </div>
  );
};

export default InputNumber;
