import _ from 'lodash';
import React from 'react';
import * as Yup from 'yup';
import cx from 'classnames';
import gql from 'graphql-tag';
import { Formik, Form } from 'formik';
import { GraphQLError } from 'graphql';
import { useMutation } from '@apollo/client';

import styles from './loginForm.module.scss';
import Label from '../../../components/form/label';

type LoginFormProps = {
  className?: string;
  onSuccess?: (token: string) => void;
};

const LoginForm: React.FC<LoginFormProps> = ({ className, onSuccess }) => {
  const [loginErrors, setLoginErrors] = React.useState<GraphQLError[]>();
  // const [login] = useMutation(
  //   gql`
  //     mutation login($input: LoginInput!) {
  //       login(input: $input) {
  //         token
  //       }
  //     }
  //   `,
  // );
  const login = ({
    variables: {
      input: { username, password },
    },
  }: {
    variables: { input: { username: string; password: string } };
  }) =>
    new Promise<{ data: { login: { token: string } } }>((resolve, reject) => {
      if (username === process.env.LOGIN_CREDENTIALS_USERNAME && password === process.env.LOGIN_CREDENTIALS_PASSWORD) {
        if (_.isNil(process.env.LOGIN_CREDENTIALS_TOKEN)) {
          throw new Error("Environment variable 'LOGIN_CREDENTIALS_TOKEN' should be defined");
        }
        resolve({ data: { login: { token: process.env.LOGIN_CREDENTIALS_TOKEN } } });
      } else {
        reject(new GraphQLError('Identifiant et/ou mot de passe incorrect.'));
      }
    });
  return (
    <div className={cx(className)}>
      {loginErrors && (
        <div className={cx({ alert: true, 'alert-danger': true })} role="alert">
          Identifiant et/ou mot de passe incorrect.
        </div>
      )}
      <Formik
        initialValues={{ username: '', password: '' }}
        validationSchema={Yup.object().shape({
          username: Yup.string().required('Obligatoire'),
          password: Yup.string().required('Obligatoire'),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          try {
            const { data } = await login({ variables: { input: values } });
            if (_.isFunction(onSuccess)) {
              onSuccess(data.login.token);
            }
          } catch (err) {
            setLoginErrors(err);
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting }) => (
          <Form className={cx(styles.form)}>
            <Label
              name="username"
              label="Identifiant"
              classNames={{ container: cx(styles.username), label: cx(styles.label) }}
            />
            <Label
              name="password"
              type="password"
              label="Mot de passe"
              classNames={{ container: cx(styles.password), label: cx(styles.label) }}
            />
            <div className={cx(styles.submit)}>
              <button
                className={cx(styles.button, { [styles.busy]: isSubmitting, [styles.disabled]: isSubmitting })}
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <>
                    <span
                      className={cx({
                        'spinner-border': true,
                        'spinner-border-sm': true,
                      })}
                      role="status"
                      aria-hidden="true"
                    />{' '}
                    Connexion en cours...
                  </>
                ) : (
                  'Se connecter'
                )}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default LoginForm;
