import _ from 'lodash';

const TOKEN_KEY = 'token';

export const getToken = () => sessionStorage.getItem(TOKEN_KEY);
export const setToken = (token: string) => sessionStorage.setItem(TOKEN_KEY, token);
export const clearToken = () => sessionStorage.removeItem(TOKEN_KEY);
export const verifyToken = (token?: string | null) => {
  switch (process.env.LOGIN_MODE) {
    case 'credentials':
      return _.isEqual(token, process.env.LOGIN_CREDENTIALS_TOKEN);
    default:
      return _.isNil(token);
  }
};
