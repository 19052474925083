import _ from 'lodash';
import React from 'react';
import cx from 'classnames';

interface HeaderProps {
  className?: string;
}
interface AnyHeaderProps extends HeaderProps {
  [x: string]: any;
}
interface BodyProps {
  className?: string;
}
interface AnyBodyProps extends BodyProps {
  [x: string]: any;
}

export default function usePanel<H extends HeaderProps = AnyHeaderProps, B extends BodyProps = AnyBodyProps>(
  Header: React.ComponentType<H> | string | null,
  Body: React.ComponentType<B> | null,
) {
  const renderHeader = React.useCallback<(props?: H) => JSX.Element | null>(
    props => {
      if (_.isNull(Header)) {
        return null;
      }
      if (_.isString(Header)) {
        return (
          <div
            {..._.merge({}, _.omit(props, ['className', 'children']), {
              className: cx('header', 'header--string', _.get(props, 'className')),
            })}
          >
            {Header}
          </div>
        );
      }
      return (
        <Header
          {...(_.merge({}, _.omit(props, ['className']), {
            className: cx('header', _.get(props, 'className')),
          }) as any)}
        />
      );
    },
    [Header],
  );
  const renderBody = React.useCallback<(props?: B) => JSX.Element | null>(
    props =>
      !_.isNull(Body) ? (
        <Body
          {...(_.merge({}, _.omit(props, ['className']), {
            className: cx('body', _.get(props, 'className')),
          }) as any)}
        />
      ) : null,
    [Body],
  );
  const noHeader = React.useMemo(() => _.isNull(Header), [Header]);
  const noBody = React.useMemo(() => _.isNull(Body), [Body]);
  const renderChildren = React.useCallback<(props: { headerProps?: H; bodyProps?: B }) => JSX.Element | null>(
    ({ headerProps, bodyProps }) =>
      !(noHeader && noBody) ? (
        <>
          {!noHeader && renderHeader(headerProps)}
          {!noBody && renderBody(bodyProps)}
        </>
      ) : null,
    [noBody, noHeader, renderBody, renderHeader],
  );
  return React.useCallback(
    (props?: { [x: string]: any }) =>
      _.merge({}, _.omit(props, ['className', 'children', 'headerProps', 'bodyProps']), {
        className: cx('container__panel', _.get(props, 'className')),
        children: renderChildren(_.pick(props, ['headerProps', 'bodyProps'])),
      }),
    [renderChildren],
  );
}
