import _ from 'lodash';
import React from 'react';
import cx from 'classnames';
import { useFormikContext, Field, ErrorMessage, FieldProps } from 'formik';

import InputControl from '../input';

export type InputProps = {
  name: string;
  className?: string;
  type?: 'text' | 'password';
};

const Input: React.FC<InputProps> = ({ name, type, className }) => {
  const { errors, touched } = useFormikContext();
  const hasError = _.get(errors, name, false) && _.get(touched, name, false);
  return (
    <>
      <Field name={name}>
        {({ field }: FieldProps) => (
          <InputControl formControl className={cx(className)} error={hasError} type={type} formikFieldProps={field} />
        )}
      </Field>
      <ErrorMessage name={name} render={msg => <div className={cx({ 'invalid-feedback': hasError })}>{msg}</div>} />
    </>
  );
};
export default Input;
