import _ from 'lodash';
import React from 'react';
import cx from 'classnames';
import { useHistory, useLocation } from 'react-router-dom';

import { ViewProps } from '../types';
import { setToken, getToken, clearToken } from '../../util/auth';

import styles from './login.module.scss';
import LoginForm from './components/loginForm';

type LoginIndexProps = ViewProps;

const LoginIndex: React.FC<LoginIndexProps> = () => {
  const history = useHistory();
  const location = useLocation();
  if (_.isString(getToken())) {
    const from = _.get(location, 'state.from', { pathname: '/production' });
    clearToken();
    history.replace(from);
  }
  return (
    <div className={cx(styles.view)}>
      <div className={cx(styles.box)}>
        <div className={cx(styles.sidebar)} />
        <div className={cx(styles.main)}>
          <h3 className={cx(styles.header)}>Meteo UI by Dataprisme</h3>
          <div className={cx(styles.content)}>
            <LoginForm
              className={cx(styles.form)}
              onSuccess={(token: string) => {
                const from = _.get(location, 'state.from', { pathname: '/production' });
                setToken(token);
                history.replace(from);
              }}
            />
            <div className={cx(styles.logo)}>
              <div className={cx(styles.container)}>
                <img alt="" src={`${process.env.PUBLIC_URL}/logo.png`} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LoginIndex;
