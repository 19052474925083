import React from 'react';
import { Route as RouterRoute } from 'react-router-dom';

import ProtectedRoute from './protected';
import { RouteProps } from '../../types';

const Route: React.FC<RouteProps> = ({ protected: protected_, ...route }) =>
  protected_ ? (
    <ProtectedRoute {...route} />
  ) : (
    <RouterRoute
      key={route.key}
      path={route.path}
      exact={route.exact}
      render={props => <route.component {...props} />}
    />
  );
export default Route;
