import React from 'react';
import cx from 'classnames';
import { FieldProps } from 'formik';

import styles from './input.module.scss';

export type InputProps = {
  error?: boolean;
  className?: string;
  formControl?: boolean;
  type?: 'text' | 'password';
  formikFieldProps?: FieldProps['field'];
};

const Input: React.FC<InputProps> = ({ type, className, formikFieldProps, error, formControl }) => (
  <input
    type={type || 'text'}
    className={cx(className, styles.input, {
      'form-control': formControl,
      'is-invalid': error,
    })}
    {...formikFieldProps}
  />
);
export default Input;
