import Production from './view/production';
import Login from './view/login';
import NotFound from './view/404';
import { RoutesProps } from './types';

const routes: RoutesProps = [
  {
    path: '/',
    redirect: '/production',
  },
  {
    path: '/login',
    exact: true,
    component: Login,
  },
  {
    path: '/production',
    exact: true,
    protected: true,
    component: Production,
  },
  {
    path: '/404',
    exact: true,
    component: NotFound,
  },
  {
    path: '*',
    component: NotFound,
  },
];

export default routes;
