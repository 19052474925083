import React from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { useQueryParam, StringParam } from 'use-query-params';

import { ViewProps } from '../types';

import styles from './404.module.scss';

type NotFoundProps = ViewProps;

const NotFound: React.FC<NotFoundProps> = () => {
  const [, setParam] = useQueryParam('collection', StringParam);
  const resetCollection = React.useCallback(() => {
    setParam(undefined);
  }, [setParam]);
  React.useEffect(() => {
    resetCollection();
  }, [resetCollection]);
  return (
    <div className={cx(styles.view)}>
      <div className={cx(styles.container)}>
        <div className={cx(styles.title)}>
          <h1>404</h1>
        </div>
        <h2>Page non trouvée</h2>
        <p>
          La page que vous cherchez a probablement été supprimée, son nom a été changé ou est temporairement
          indisponible.
        </p>
        <Link className={cx(styles.link)} to="/">
          Retour à l&apos;acceuil
        </Link>
      </div>
    </div>
  );
};
export default NotFound;
