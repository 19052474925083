import _ from 'lodash';
import React from 'react';
import cx from 'classnames';

import InputNumber from './inputNumber';

import type { CommonInputNumberProps } from './inputNumber';

function useNumberAsString(
  type: 'integer' | 'float',
  value?: number,
  { precision }: { precision?: number } = {},
): {
  value: string;
  toNumber: (s: string) => number;
  isSafe: (value: any) => boolean;
  updateValue: (value: string) => void;
} {
  const isSafe = React.useMemo(() => {
    switch (type) {
      case 'integer':
        return _.isSafeInteger;
      case 'float':
        return _.isFinite;
      default:
        throw new Error(`Unknown type '${type}'`);
    }
  }, [type]);
  const toString = React.useCallback(
    (v?: number) =>
      isSafe(v)
        ? `${
            type === 'float' && !_.every([v, precision], _.isNil)
              ? Math.round((v! + Number.EPSILON) * 10 ** precision!) / 10 ** precision!
              : v
          }`
        : '',
    [isSafe, precision, type],
  );
  const toNumber = React.useCallback(
    (s: string) => {
      switch (type) {
        case 'integer':
          return parseInt(s, 10);
        case 'float':
          return parseFloat(s);
        default:
          throw new Error(`Unknown type '${type}'`);
      }
    },
    [type],
  );
  const [valueAsString, updateValueAsString] = React.useState(toString(value));
  React.useEffect(() => {
    updateValueAsString(toString(value));
  }, [toString, value]);
  return React.useMemo(
    () => ({ isSafe, toNumber, value: valueAsString, updateValue: updateValueAsString }),
    [isSafe, toNumber, valueAsString],
  );
}

const StockInput: React.FC<CommonInputNumberProps> = ({ value, readOnly, className, update }) => {
  const { isSafe, toNumber, value: stock } = useNumberAsString('integer', value);
  const updateStock = React.useCallback(
    (s: string) => {
      if (_.isFunction(update)) {
        const newValue = toNumber(s);
        if (isSafe(newValue)) {
          update(newValue);
        }
      }
    },
    [isSafe, toNumber, update],
  );
  return (
    <InputNumber
      min="0"
      step="1"
      value={stock}
      readOnly={readOnly}
      accepts={['integer']}
      onChange={updateStock}
      className={cx(className)}
      max={`${Number.MAX_SAFE_INTEGER}`}
    />
  );
};

export default StockInput;
