import { useMemo } from 'react';

import useToggle from './useToggle';

export default function useHover(): { hovered: boolean; bind: { onMouseEnter: () => void; onMouseLeave: () => void } } {
  const { set, on: hovered } = useToggle();
  return useMemo(
    () => ({
      hovered,
      bind: {
        onMouseEnter: () => set(true),
        onMouseLeave: () => set(false),
      },
    }),
    [hovered, set],
  );
}
