import _ from 'lodash';
import React, { useState } from 'react';
import cx from 'classnames';
import { gql, useQuery } from '@apollo/client';

import Select from 'react-select';

import { FaRegArrowAltCircleRight, FaRegArrowAltCircleLeft } from 'react-icons/fa';

import styles from './productionForm.module.scss';

export type ArticleSelectProps = {
  className?: string;
  onClear?: () => void;
  onChange?: (value: Article) => void;
};

export type Article = {
  article: string;
  libelle?: string;
  // eslint-disable-next-line camelcase
  uc_par_roll?: number;
  // eslint-disable-next-line camelcase
  coefficient_kg?: number;
  // eslint-disable-next-line camelcase
  coefficient_roll?: number;
  // eslint-disable-next-line camelcase
  stock_min?: number;
  // eslint-disable-next-line camelcase
  stock_max?: number;
};

type WithTypename<T = any> = Omit<T, '__typename'> & { __typename: string };

const ARTICLES_QUERY = gql`
  {
    articles {
      article
      libelle
      uc_par_roll
      coefficient_kg
      coefficient_roll
      stock_min
      stock_max
    }
  }
`;

const mapOption = (record: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { article, libelle, __typename, ...props } = record;
  return { label: `${article} - ${libelle}`, value: { article, libelle, ...props } };
};

const mapOptions = (articles: any) => {
  const options = articles.map((record: WithTypename<Article>) => mapOption(record));
  return options;
};

const ArticleSelect: React.FC<ArticleSelectProps> = ({ className, onClear, onChange }) => {
  const [options, setOptions] = useState([]);
  const [currentValue, setCurrentValue] = useState<any>();

  const { loading, error } = useQuery(ARTICLES_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: data => setOptions(mapOptions(data.articles)),
  });

  if (loading) return <div className={styles['select-container']}>Chargement ...</div>;
  if (error) return <div>Error! {error}</div>;

  return (
    <div className={styles['select-container']}>
      <Select<{ label: string; value: Article }>
        isClearable
        options={options}
        value={currentValue}
        classNamePrefix="select"
        placeholder="Rechercher..."
        loadingMessage={() => 'Chargement...'}
        className={cx(className, styles.select)}
        noOptionsMessage={() => 'Aucun article trouvé.'}
        onChange={(value, { action }) => {
          switch (action) {
            case 'select-option':
              if (_.isFunction(onChange) && !(_.isNil(value) || _.isArray(value))) {
                setCurrentValue(value);
                onChange((value as { value: Article }).value);
              }
              break;
            case 'clear':
              if (_.isFunction(onClear)) {
                setCurrentValue(undefined);
                onClear();
              }
              break;
            default:
              break;
          }
        }}
      />
      <div className={styles.controls}>
        <FaRegArrowAltCircleLeft
          size="24px"
          color="#808080"
          onClick={() => {
            if (currentValue === undefined) return;

            const index = options.findIndex(value => _.isEqual(value, currentValue));
            if (index === 0 || index === -1) return;

            setCurrentValue(options[index - 1]);
            if (_.isFunction(onChange)) {
              onChange((options[index - 1] as { value: Article }).value);
            }
          }}
        />
        <FaRegArrowAltCircleRight
          size="24px"
          color="#808080"
          onClick={() => {
            if (currentValue === undefined) return;

            const index = options.findIndex(value => _.isEqual(value, currentValue));
            if (index === -1 || index === options.length - 1) return;

            setCurrentValue(options[index + 1]);
            if (_.isFunction(onChange)) {
              onChange((options[index + 1] as { value: Article }).value);
            }
          }}
        />
      </div>
    </div>
  );
};

export default ArticleSelect;
