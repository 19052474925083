import _ from 'lodash';
import React from 'react';
import cx from 'classnames';
import { IconBaseProps } from 'react-icons';
import { useHistory } from 'react-router-dom';
import { FaSignOutAlt } from 'react-icons/fa';

import { ViewProps } from '../types';
import { clearToken } from '../../util/auth';

import styles from './production.module.scss';
import Container from './components/container';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Logo from './img/logo-danone.png';

export type SidebarItemProps = {
  className?: string;
  collapsed?: boolean;
  label: string;
  Icon: React.ComponentType<IconBaseProps>;
  CollapsedIcon?: React.ComponentType<IconBaseProps>;
  onClick?: (e: any) => void;
};

const SidebarItem: React.FC<SidebarItemProps> = ({ className, collapsed, label, Icon, CollapsedIcon, onClick }) => (
  <div
    className={cx(className, styles.sidebar__item, { [styles['sidebar__item--clickable']]: _.isFunction(onClick) })}
    onClick={onClick}
  >
    {React.createElement(collapsed && CollapsedIcon ? CollapsedIcon : Icon, {
      className: cx(styles.sidebar__item__icon),
    })}
    {!collapsed && (
      <div className={cx(styles.sidebar__item__label, styles['sidebar__item__label--textOverflow'])}>{label}</div>
    )}
  </div>
);

type ProductionProps = ViewProps;

const Production: React.FC<ProductionProps> = () => {
  const history = useHistory();
  const logout = () => {
    clearToken();
    history.replace('/');
  };

  return (
    <div className={styles.container}>
      <div className={styles.sidebar}>
        <div>
          <img src={Logo} alt="" className={cx(styles.logo, styles.sidebar__item)} />
        </div>
        <div className={styles['sidebar__section--actions']}>
          <SidebarItem Icon={FaSignOutAlt} label="Déconnexion" onClick={logout} collapsed />
        </div>
      </div>
      <Container className={cx(styles.main)} />
    </div>
  );
};
export default Production;
