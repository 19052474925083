import React from 'react';
import cx from 'classnames';

import ProductionForm from './form';
import styles from './container.module.scss';
import usePanel from '../../../hooks/usePanel';

type ContainerProps = {
  className?: string;
};

type FormPanelBodyProps = {
  className?: string;
};

const FormPanelBody: React.FC<FormPanelBodyProps> = ({ className }) => {
  return <ProductionForm className={cx(className)} />;
};

const Container: React.FC<ContainerProps> = ({ className }) => {
  const getFormPanelProps = usePanel(null, FormPanelBody);
  return (
    <div className={cx(className, styles.container)}>
      <div className={cx(styles.header)}>
        <h4>METEO - CHOIX DE PRODUCTION</h4>
      </div>
      <div className={cx(styles.main)}>
        <div className={cx(styles.scroll)}>
          <div {...getFormPanelProps({ className: cx(styles.panel) })} />
        </div>
      </div>
    </div>
  );
};

export default Container;
