import React from 'react';
import cx from 'classnames';

import Input, { InputProps } from './input';

type LabelProps = Omit<InputProps, 'className'> & {
  label: string;
  classNames?: {
    container?: string;
    label?: string;
    input?: string;
  };
};

const Label: React.FC<LabelProps> = ({ name, type, label, classNames }) => (
  <div className={classNames && cx(classNames.container)}>
    <label className={classNames && cx(classNames.label)} htmlFor={name}>
      {label}
      <Input name={name} type={type} className={classNames && cx(classNames.input)} />
    </label>
  </div>
);
export default Label;
