import _ from 'lodash';
import React from 'react';
import cx from 'classnames';

import InputNumber from './inputNumber';

import type { InputNumberProps, CommonInputNumberProps } from './inputNumber';

type ROLLInputProps = CommonInputNumberProps<{
  precision?: number;
  ucParRoll: number;
  coefficientKg: number;
  displayUc: boolean;
}>;

function useNumberAsString(
  type: 'integer' | 'float',
  value?: number,
  { precision }: { precision?: number } = {},
): {
  value: string;
  toNumber: (s: string) => number;
  isSafe: (value: any) => boolean;
  updateValue: (value: string) => void;
} {
  const isSafe = React.useMemo(() => {
    switch (type) {
      case 'integer':
        return _.isSafeInteger;
      case 'float':
        return _.isFinite;
      default:
        throw new Error(`Unknown type '${type}'`);
    }
  }, [type]);
  const toString = React.useCallback(
    (v?: number) =>
      isSafe(v)
        ? `${
            type === 'float' && !_.every([v, precision], _.isNil)
              ? Math.round((v! + Number.EPSILON) * 10 ** precision!) / 10 ** precision!
              : v
          }`
        : '',
    [isSafe, precision, type],
  );
  const toNumber = React.useCallback(
    (s: string) => {
      switch (type) {
        case 'integer':
          return parseInt(s, 10);
        case 'float':
          return parseFloat(s);
        default:
          throw new Error(`Unknown type '${type}'`);
      }
    },
    [type],
  );
  const [valueAsString, updateValueAsString] = React.useState(toString(value));
  React.useEffect(() => {
    updateValueAsString(toString(value));
  }, [toString, value]);
  return React.useMemo(
    () => ({ isSafe, toNumber, value: valueAsString, updateValue: updateValueAsString }),
    [isSafe, toNumber, valueAsString],
  );
}

const ROLLInput: React.FC<ROLLInputProps> = ({
  value,
  readOnly,
  className,
  precision,
  update,
  ucParRoll,
  coefficientKg,
  displayUc,
}) => {
  const {
    isSafe,
    toNumber,
    value: inputValue,
    updateValue: setInputValue,
  } = useNumberAsString('float', value, {
    precision,
  });
  const updateInputValue = React.useCallback(
    (s: string) => {
      if (_.isFunction(update)) {
        const trimmedString = s.trim();
        if (_.endsWith(trimmedString, '.')) {
          setInputValue(trimmedString);
        } else if (_.isEmpty(trimmedString)) {
          update(undefined);
        } else {
          const newValue = toNumber(trimmedString);
          if (isSafe(newValue)) {
            update(newValue);
          }
        }
      }
    },
    [isSafe, setInputValue, toNumber, update],
  );
  const components: InputNumberProps['components'] = React.useMemo(
    () => ({
      Info: ({ className, value }) => {
        if (!_.isNumber(value)) return <br />;
        const uvc: number = value * ucParRoll;
        const kg: number = uvc * coefficientKg;
        const info: string = displayUc ? `${uvc.toFixed(1)} uvc` : `${kg.toFixed(1)} kg`;
        return <div className={cx(className)}>{info}</div>;
      },
    }),
    [ucParRoll, coefficientKg, displayUc],
  );
  return (
    <InputNumber
      min="0"
      step="1"
      value={inputValue}
      accepts={['float']}
      readOnly={readOnly}
      precision={precision}
      components={components}
      className={cx(className)}
      onChange={updateInputValue}
    />
  );
};

export default ROLLInput;
