import { useMemo } from 'react';

import useToggle from './useToggle';

export default function useFocus(): { focused: boolean; bind: { onFocus: () => void; onBlur: () => void } } {
  const { set, on: focused } = useToggle();
  return useMemo(
    () => ({
      focused,
      bind: {
        onFocus: () => set(true),
        onBlur: () => set(false),
      },
    }),
    [focused, set],
  );
}
