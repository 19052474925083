import _ from 'lodash';
import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { Route as RouterRoute, RouteComponentProps, Redirect } from 'react-router-dom';

import { RouteProps } from '../../types';

// TODO: Missing API
import { getToken, verifyToken } from '../../util/auth';

type ProtectedRouteProps = Omit<RouteProps, 'protected'>;
type WrappedComponentProps = RouteComponentProps & Omit<ProtectedRouteProps, 'path'>;

const WrappedComponent: React.FC<WrappedComponentProps> = ({ component: Component, ...props }) => {
  // const { loading, error, data } = useQuery(
  //   gql`
  //     query {
  //       me {
  //         id
  //       }
  //     }
  //   `,
  //   { fetchPolicy: 'network-only' },
  // );
  const [{ loading, error, data }] = React.useState({
    loading: false,
    error: false,
    data: { me: verifyToken(getToken()) ? "It's me...Mario !!!" : null },
  });
  if (loading) {
    return null;
  }
  if (error || _.isNull(data.me)) {
    const { location } = props;
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { from: location },
        }}
      />
    );
  }
  return <Component {...props} />;
};

const ProtectedRoute: React.FC<ProtectedRouteProps> = route => (
  <RouterRoute
    key={route.key}
    path={route.path}
    exact={route.exact}
    render={props => <WrappedComponent {...props} component={route.component} />}
  />
);

export default ProtectedRoute;
