import React from 'react';
import ReactDOM from 'react-dom';

import './scss/main.scss';

import { unregister } from './serviceWorker';

import App from './components/app';

unregister();
ReactDOM.render(<App />, document.getElementById('root'));
